import cx from 'classnames'
import ReactMarkdown from 'react-markdown'
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'
import rehypeExternalLinks from 'rehype-external-links'
import rehypeSanitize from 'rehype-sanitize'
import rehypeStringify from 'rehype-stringify'

export const Markdown: React.FC<ReactMarkdownOptions> = ({ children, className, ...props }) => (
  <div className={cx('prose', className)}>
    <ReactMarkdown
      rehypePlugins={[
        rehypeSanitize,
        rehypeStringify,
        [rehypeExternalLinks, { target: '_blank', rel: ['noopener noreferrer'] }],
      ]}
      skipHtml
      {...props}
    >
      {children}
    </ReactMarkdown>
  </div>
)
