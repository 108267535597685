import { COOKIE_NAME } from '@axieinfinity/kukki'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'sonner'

import { cookie } from '#/core/cookie'
import { logger } from '#/core/logger'
import { services } from '#/core/services'

export const userProfileQueryKey = ['user_profile']
export const useUserProfile = () => {
  return useQuery({
    queryKey: userProfileQueryKey,
    queryFn: async () => {
      const accessToken = cookie.get(COOKIE_NAME.ACCESS_TOKEN)
      const refreshToken = cookie.get(COOKIE_NAME.REFRESH_TOKEN)
      if (!accessToken && !refreshToken) {
        return null
      }
      try {
        const request = await services.request('get /v2/users/profile')

        return request
      } catch (e) {
        logger.error(e, { error: e }, 'useUserProfile')

        return null
      }
    },
    select(request) {
      if (request === null) return null

      const { response, result, error } = request
      if (response.status >= 500) {
        logger.error('An error occured while trying to connect to Mavis Hub server status >= 500', { response })
        toast.error(
          'An error occured while trying to connect to Mavis Hub server'
        )

        return null
      }

      if (error) {
        logger.error(error.message, error)
        toast.error(error.message)

        return null
      }

      return result.asProfile
    },
    refetchOnWindowFocus: true,
  })
}
