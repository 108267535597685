import { config } from '@mavishub/config'
import { ServiceClient } from '@axieinfinity/hub-services'

import { clearTokens } from './browser/request'
import { cookie } from './cookie'

export const services = new ServiceClient({
	prefixUrl: config.serverGateway,
	getCookie: (name) => cookie.get(name),
	setCookie: cookie.set,
	clearAuthCookies: clearTokens,
})
