import type { CookieSetOptions } from '@axieinfinity/kukki'
import { CookieManager } from '@axieinfinity/kukki'

export const defaultCookieOptions: CookieSetOptions = {
  path: '/',
  secure: process.env.NODE_ENV === 'production',
  sameSite: 'lax',
}

export const oneYearExpiryDate = () => new Date(new Date().setFullYear(new Date().getFullYear() + 1))

export type ObservableCookie = Record<string, string>
export const cookie = new CookieManager<ObservableCookie>()
