import { GameDetails } from '@axieinfinity/hub-services'
import { atom, useAtom } from 'jotai'

import { sleep } from '#/utils'

import { useCaptureEvent } from '../tracking'

type DialogType = undefined | 'manage' | 'install' | 'update' | 'uninstall'

type DialogAtom =
	| {
		type: DialogType
		resource: GameDetails
	}
	| undefined

const dialogAtom = atom<DialogAtom>(undefined)

export const useGameDialog = () => {
	const [dialog, setDialog] = useAtom(dialogAtom)
	const captureEvent = useCaptureEvent()

	const openDialog = (type: DialogType, resource: GameDetails) => {
		const { id, name, slug } = resource
		captureEvent(`${type} Game dialog Opened`, {
			game: { id, name, slug },
		})

		setDialog({ type, resource })
	}

	const closeDialog = async () => {
		captureEvent(`${dialog?.type} Game dialog Closed`, {
			game: {
				id: dialog?.resource.id,
				name: dialog?.resource.name,
				slug: dialog?.resource.slug,
			},
		})

		setDialog((old) => ({ ...old, type: undefined } as DialogAtom))
		// fade out animation delay
		await sleep(420)
		setDialog(undefined)
	}

	const isVisible = (type: Exclude<DialogType, undefined>) => dialog?.type === type

	return { isVisible, resource: dialog?.resource, openDialog, closeDialog }
}
