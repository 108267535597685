import { CookieManager } from '@axieinfinity/kukki'
import { config } from '@mavishub/config'
import { ClientJS } from 'clientjs'
import { useAtomCallback } from 'jotai/utils'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import UAParser from 'ua-parser-js'

import { logger } from '#/core/logger'
import { playingGamesAtom } from '#/core/stores'

import { version } from '../../package.json'
import { useUserProfile } from './query'

type BaseEventData = {
  uuid: string
  event: string
  timestamp: string
  ref: string | null
  session_id: string
  user_id: string
  offset: number
}

type IdentifyEventData = BaseEventData & {
  device_name: string | null
  device_id: string | null
  platform_name: string | null
  platform_version: string | null
  internet_type: string | null
  build_version: string | null
  user_properties: object | undefined
}

type TrackEventData = BaseEventData & {
  action_properties: object | undefined
}

type ScreenEventData = BaseEventData & {
  screen: string | undefined
  screen_properties: object | undefined
}

enum EventType {
  Identify = 'identify',
  Track = 'track',
  Screen = 'screen',
}

type MossEvent = {
  type: EventType
  data: IdentifyEventData | TrackEventData | ScreenEventData
}

// Tracking flow
const eventQueue: MossEvent[] = []
const keyPrefix = '__moss__'
const anonymousKey = `${keyPrefix}user`
const sessionKey = `${keyPrefix}online`
const batchSize = 20
const endpoint = 'https://x.skymavis.com/track'
const maxReportRetryTimes = 3
const maxReportTimeout = 5_000 // 5s
const heartbeatInterval = 2_000 // 2s
const sessionTimeout = 300_000 // 5m
const maxHeartbeatCount = sessionTimeout / heartbeatInterval
const inactiveEvent = 'Inactive'
const heartbeatEvent = 'heartbeat'
const ingameEvent = 'ingame'
const loginEvent = 'Logged in'
const logoutEvent = 'Logged out'

let currentPromise: Promise<void> | null = null
let retriedTime = 0
let eventRef: string | null = null
let offset = 0
let heartbeat: NodeJS.Timer | null = null
let heartbeatCount = 0

const generateTimestamp = (value?: Date) => {
  const date = value ? value : new Date()

  const dtf = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  })
    .format(date)
    .replace(/(\d+)\/(\d+)\/(\d+),/, '$3-$1-$2')

  return dtf
}

const sendRecords = async () => {
  if (eventQueue.length === 0) return

  const mossController = new AbortController()
  const count = eventQueue.length
  const apiKey = config.mossKey

  currentPromise = fetch(endpoint, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${apiKey}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      events: [...eventQueue.slice(0, count)],
    }),
    signal: mossController.signal,
  })
    .then((response) => {
      if (response.ok) {
        retriedTime = 0
        eventQueue.splice(0, count)
      }
    })
    .catch(() => {
      retriedTime++
      if (retriedTime < maxReportRetryTimes) {
        sendRecords()
      }
    })
    .finally(() => (currentPromise = null))
  setTimeout(() => mossController.abort(), maxReportTimeout)
}

const getGenLocalStorage = (key: string) => {
  let value = sessionStorage.getItem(key)
  if (!value) {
    value = crypto.randomUUID()
    sessionStorage.setItem(key, value)
  }

  return value
}
const getSessionId = () => getGenLocalStorage(sessionKey)

const cookie = new CookieManager()
const client = new ClientJS()

type MossProps = {
  captureEvent: (event: string, props?: object) => void
  captureScreen: (event: string, props?: object) => void
}

const MossContext = createContext<MossProps>({
  captureEvent: () => undefined,
  captureScreen: () => undefined,
})

export const MossProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [sessionCount, setSessionCount] = useState(0)
  const { data: profile } = useUserProfile()
  const authorized = !!profile
  const playingGames = useAtomCallback(useCallback((get) => get(playingGamesAtom), []))
  const isInGame = useAtomCallback(useCallback((get) => get(playingGamesAtom).size > 0, []))

  useEffect(() => {
    if (authorized) {
      setSessionCount(sessionCount + 1)
      addRecord(loginEvent, EventType.Track, { sessionCount })
      if (!heartbeat) startHeartbeat()
    } else {
      if (sessionCount > 0) {
        addRecord(logoutEvent, EventType.Track, { sessionCount })
      }
      if (heartbeat) stopHeartbeat()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorized])

  const startHeartbeat = () => {
    heartbeat = setInterval(() => {
      if (isInGame()) {
        heartbeatCount = 0
        captureEvent(ingameEvent, {
          games: [...playingGames()],
          sessionCount,
        })
      } else {
        if (heartbeatCount >= maxHeartbeatCount) stopHeartbeat()
        else captureEvent(heartbeatEvent, { sessionCount })
      }
    }, heartbeatInterval)
    logger.info('[moss] start heartbeat')
  }

  const stopHeartbeat = () => {
    if (authorized) addRecord(inactiveEvent, EventType.Track)
    if (heartbeat) clearInterval(heartbeat)
    sessionStorage.removeItem(sessionKey)
    heartbeat = null
    heartbeatCount = 0
  }

  const platform = async () => {
    const { moss } = window.bridge || {}

    if (moss) {
      return {
        device_name: (await moss.getDeviceName()) || null,
        device_id: (await moss.getDeviceID()) || null,
        platform_name: await moss.getOSName(),
        platform_version: await moss.getOSVersion(),
        internet_type: (await moss.getInternetType()) || null,
        build_version: await moss.getAppVersion(),
      }
    }

    const userAgent = new UAParser(navigator.userAgent)

    return {
      device_name: null,
      device_id: String(client.getFingerprint()),
      platform_name: userAgent.getBrowser().name,
      platform_version: userAgent.getBrowser().version,
      internet_type: null,
      build_version: version,
    }
  }

  const inactiveEvents = [logoutEvent, inactiveEvent, heartbeatEvent, ingameEvent]
  const addRecord = async (event: string, type: EventType, props?: object) => {
    if (eventRef === null && type !== EventType.Identify && offset === 0) {
      await addRecord(event, EventType.Identify, props)

      return
    }

    heartbeatCount = inactiveEvents.includes(event) ? heartbeatCount + 1 : 0
    if (!inactiveEvents.includes(event) && !heartbeat) startHeartbeat()

    let userId: string = crypto.randomUUID()
    if (!profile) {
      const previousUserId = cookie.get(anonymousKey)
      if (
        previousUserId &&
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(previousUserId)
      ) {
        userId = previousUserId
      } else {
        cookie.set(anonymousKey, userId, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) })
      }
    } else {
      userId = profile.userID
    }

    const baseData = {
      uuid: crypto.randomUUID(),
      event: event,
      ref: event === heartbeatEvent || event === ingameEvent ? '' : eventRef,
      timestamp: generateTimestamp(),
      session_id: getSessionId(),
      user_id: userId,
      offset: event === heartbeatEvent || event === ingameEvent ? -1 : offset++,
    }

    let data: IdentifyEventData | TrackEventData | ScreenEventData
    const { moss } = window.bridge || {}

    const arr = event.split(' ')

    switch (true) {
      case type === EventType.Identify:
        data = {
          ...baseData,
          ...(await platform()),
          user_properties: {
            ...props,
            platform_arch: (await moss?.getOSArch()) || null,
            user_agent: navigator.userAgent,
            canvas_print: client.getCanvasPrint(),
          },
        } as IdentifyEventData
        break
      case type === EventType.Screen:
        data = {
          ...baseData,
          screen: arr.slice(1, arr.length - 1).join(' '),
          screen_properties: props,
        } as ScreenEventData
        break
      default: // Type.Track
        data = {
          ...baseData,
          action_properties: props,
        } as TrackEventData
    }

    const eventRecord = {
      type: type as EventType,
      data: data,
    }

    eventRef = event === heartbeatEvent || event === ingameEvent ? eventRef : event
    if (type === EventType.Identify) eventQueue.unshift(eventRecord)
    else eventQueue.push(eventRecord)

    if ((!currentPromise && eventQueue.length >= batchSize) || event === logoutEvent || event === inactiveEvent) {
      sendRecords()
    }
  }

  const captureEvent = async (event: string, props?: object) => {
    if (!authorized) return
    await addRecord(event, EventType.Track, props)
  }

  const captureScreen = async (screen: string, props?: object) => {
    if (!authorized) return
    const event = `Access ${screen} Screen`
    // Prevent duplicated records occured by re-rendering of React.StrictMode
    if (event === eventRef) return
    await addRecord(event, EventType.Screen, props)
  }

  return <MossContext.Provider value={{ captureEvent, captureScreen }}>{children}</MossContext.Provider>
}

export const useMoss = () => useContext(MossContext)

export const useCaptureScreen = (screen: string) => {
  const { captureScreen } = useMoss()
  useEffect(() => {
    captureScreen(screen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen])
}

export const useCaptureEvent = (_prefix?: string) => useMoss().captureEvent
