import { DownloadProgress, RequestInstallGamePayload, StoreInstalledGames } from '@axieinfinity/kataru'
import { atom } from 'jotai'

export type DownloadQueueItem = RequestInstallGamePayload
export const downloadQueueAtom = atom<DownloadQueueItem[]>([])

export const installedGamesAtom = atom<StoreInstalledGames>({})

export const playingGamesAtom = atom<Set<string>>(new Set<string>())

export const downloadProgressAtom = atom<DownloadProgress | undefined>(undefined)

export const recentlyPlayedAtom = atom<Array<[string, unknown]>>([])
