import { Logger, LogLevel, Metadata, prepareMetadata } from '@axieinfinity/hub-logger'
import * as Sentry from '@sentry/react'

type Transport = (
  level: LogLevel,
  message: string | Error,
  metadata: Metadata,
  timestamp: number
) => void

export const consoleTransport: Transport = (
  level,
  message,
  metadata,
  timestamp
) => {
  // Stack trace
  const extra = Object.keys(metadata).length
    ? ' ' + JSON.stringify(metadata, null, 2)
    : ''
  const log = {
    [LogLevel.Debug]: console.debug,
    [LogLevel.Info]: console.info,
    // eslint-disable-next-line no-console
    [LogLevel.Log]: console.log,
    [LogLevel.Warn]: console.warn,
    [LogLevel.Error]: console.error,
  }[level]

  log(
    `[${new Date(timestamp).toISOString()}]`,
    message.toString(),
    extra
  )
}

export const sentryTransport: Transport = (
  level,
  message,
  { type, tags, ...metadata },
  timestamp
) => {
  const meta = prepareMetadata(metadata)

  // Check message to report a breadcrumb
  if (typeof message === 'string') {
    const severity = (
      {
        [LogLevel.Debug]: 'debug',
        [LogLevel.Info]: 'info',
        [LogLevel.Log]: 'log', // Sentry value here is undefined
        [LogLevel.Warn]: 'warning',
        [LogLevel.Error]: 'error',
      } as const
    )[level]

    Sentry.addBreadcrumb({
      message,
      data: meta,
      type: type || 'default',
      level: severity,
      timestamp: timestamp / 1000, /// Sentry expects seconds
    })

    /**
     * Send all higher levels with `captureMessage`,
     * with appropriate severity level
     */
    if (level === 'error' || level === 'warn' || level === 'log') {
      const messageLevel = ({
        [LogLevel.Log]: 'log',
        [LogLevel.Warn]: 'warning',
        [LogLevel.Error]: 'error',
      }[level] || 'log') as Sentry.Breadcrumb['level']

      Sentry.captureMessage(message, {
        level: messageLevel,
        tags,
        extra: meta,
      })
    }
  } else {
    Sentry.captureException(message, {
      tags,
      extra: meta,
    })
  }
}

export const logger = new Logger({ enabled: true })

logger.addTransport(consoleTransport)
logger.addTransport(sentryTransport)
