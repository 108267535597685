import { getSearchParams, PaginationInput } from '@axieinfinity/hub-services'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'sonner'

import { logger } from '#/core/logger'
import { services } from '#/core/services'

const defaultPagination = {
	pageSize: 5,
	page: 1,
	desc: true,
}
const defaultResponse = {
	data: [],
	pagination: {
		...defaultPagination,
		lastPage: 1,
		totalRows: 0,
	},
}

const patchNotesQueryKeys = {
	all: ['patchnotes'] as const,
	details: (slug: string) => [...patchNotesQueryKeys.all, slug] as const,
	filter: (slug: string, pagination: PaginationInput = defaultPagination) =>
		[...patchNotesQueryKeys.details(slug), pagination] as const,
}
export const usePatchNotes = (
	slug: string,
	pagination: PaginationInput = defaultPagination
) => {
	return useQuery({
		queryKey: patchNotesQueryKeys.filter(slug, pagination),
		queryFn: async () => {
			try {
				const { response, error, result } = await services.request(
					'get /v2/public/games/[slug]/patchnotes?[query]',
					{
						slug,
						query: getSearchParams({ ...defaultPagination, ...pagination }).toString(),
					}
				)
				if (response.status >= 500) {
					throw new Error(response.statusText)
				} else if (error) {
					if (response.status === 404) {
						return defaultResponse
					} else {
						toast.error(error.message)

						return defaultResponse
					}
				} else {
					return result
				}
			} catch (e) {
				logger.error(e, { error: e }, 'usePatchNotes')

				return defaultResponse
			}
		},
	})
}
